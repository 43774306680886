// @flow

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PAGES } from '../page-urls';
import AccountNav from './AccountNav';
import { DEVICE_TYPE } from '../constants';
import HamburgerMenu from './HamburgerMenu';
import { HeaderLogo } from './HeaderLogo';

function getPartnerUrlPrefix(isVirginPulse, isPersonifyHealth) {
  if (isVirginPulse) {
    return PAGES.VIRGIN_PULSE_LANDING.url;
  }
  if (isPersonifyHealth) {
    return PAGES.PERSONIFY_HEALTH_LANDING.url;
  }
  return PAGES.PARTNER_LANDING.url;
}

export const MainHeader = memo(props => {
  const location = useLocation();
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const accessCode = accessCodeConfig?.accessCode;
  const alias =
    accessCodeConfig?.alias ||
    accessCodeConfig?.webTemplate?.alias?.toLowerCase();
  const isVirginPulse = useSelector(state => state.isVirginPulse);
  const isPersonifyHealth = useSelector(state => state.isPersonifyHealth);
  const partnerUrlPrefix = getPartnerUrlPrefix(
    isVirginPulse,
    isPersonifyHealth
  );
  const accessCodeParam = accessCode ? `?access_code=${accessCode}` : '';
  const homeLink = alias
    ? `${partnerUrlPrefix}/${alias}${accessCodeParam}`
    : '/';
  const title = useSelector(state => state.headerBarTitle);
  const device = useSelector(state => state.device);
  const isMobile = device !== DEVICE_TYPE.DESKTOP;
  const shouldHide = isMobile && location.pathname.includes('nrt');

  return shouldHide ? null : (
    <header className="brand-bar">
      <div className="wrapper">
        <HeaderLogo />
        {title && <div className="brand-bar-copy">{title}</div>}
        <AccountNav />
        <HamburgerMenu homeLink={homeLink} />
      </div>
    </header>
  );
});
