import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import TypeWriter from 'typewriter-effect';
import { usePageScrolledWithTopBuffer } from '../common-components/usePageScrolled';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import TargetSpanishFlyer from './target-spanish-flyer';
import HeroSensorAnimation from '../common-components/hero-sensor-animation';
import ResponsiveHeroImageVape from '../common-components/responsive-hero-image-vape';
import HeroOther from '../common-components/hero-other';
import { usePartnerConfig } from '../usePartnerConfig';
import NicTypeSwitcher from '../common-components/NicTypeSwitcher';
import { ADDICTION_TYPES, CLASS_NAMES_BY_NIC_TYPE } from '../../../constants';
import styles from '../../../../css/UnifiedLanding.module.scss';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function Hero({ callToAction, accessCode, nicType }) {
  const location = useLocation();
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const ctaButtonRef = useRef();
  const isInBuffer = usePageScrolledWithTopBuffer(false, ctaButtonRef, 65);
  const { isTarget, isCdphp, isBcnc, isCigOnly } = usePartnerConfig();
  const { str, reactNodeToString } = useI18n();
  const heroVersion = nicType;
  const label = str(I18N_STR.getStarted);

  const HERO_VERSIONS = {
    // SMOKING is the default hero version
    [ADDICTION_TYPES.SMOKING]: {
      fixed: str(I18N_STR.heroTitle),
      typewriterWords: null,
      copy: str(I18N_STR.heroCopy),
      copyForCdphp: str(I18N_STR.cdphpHeroCopy),
      heroImage: <HeroSensorAnimation />,
    },
    [ADDICTION_TYPES.VAPING]: {
      fixed: str(I18N_STR.heroVapeTitle),
      typewriterWords: [
        reactNodeToString(str(I18N_STR.heroTypeWriterWord1)),
        reactNodeToString(str(I18N_STR.heroTypeWriterWord2)),
        reactNodeToString(str(I18N_STR.heroTypeWriterWord3)),
        reactNodeToString(str(I18N_STR.heroTypeWriterWord4)),
        reactNodeToString(str(I18N_STR.heroTypeWriterWord5)),
      ],
      copy: str(I18N_STR.heroVapeCopy),
      heroImage: <ResponsiveHeroImageVape />,
    },
    [ADDICTION_TYPES.OTHER]: {
      fixed: str(I18N_STR.heroOtherTitle),
      typewriterWords: null,
      copy: str(I18N_STR.heroOtherCopy),
      heroImage: <HeroOther />,
    },
  };

  const onClickHandler = () => {
    track(`${WEBREG_PREFIX}:landing-cta-click`, {
      'Page Path': location.pathname,
      accessCode: accessCodeConfig?.accessCode || accessCode,
      buttonLocation: 'hero',
      label,
      isDocked: isInBuffer,
    });
    callToAction();
  };

  return (
    <>
      <section
        className={classnames(
          styles.hero,
          styles[CLASS_NAMES_BY_NIC_TYPE[nicType]]
        )}
      >
        {!isCigOnly && <NicTypeSwitcher />}
        <div className={styles.content}>
          <h1>
            {HERO_VERSIONS[heroVersion].fixed}
            {HERO_VERSIONS[heroVersion].typewriterWords && (
              <>
                <br />
                <TypeWriter
                  options={{
                    strings: HERO_VERSIONS[heroVersion].typewriterWords,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </>
            )}
          </h1>
          <p>
            {isCdphp && HERO_VERSIONS[heroVersion]['copyForCdphp']
              ? HERO_VERSIONS[heroVersion]['copyForCdphp']
              : HERO_VERSIONS[heroVersion]['copy']}
          </p>
          <button
            ref={ctaButtonRef}
            className={classnames(styles.mainCtaButtonMobile, {
              [styles.isDocked]: isInBuffer,
            })}
            onClick={onClickHandler}
          >
            <span>{label}</span>
          </button>
          {HERO_VERSIONS[heroVersion].heroImage}
          <button
            className={classnames(styles.mainCtaButtonDesktop, {
              [styles.isDocked]: isInBuffer,
            })}
            onClick={onClickHandler}
          >
            <span>{label}</span>
          </button>
          {accessCodeConfig?.webTemplate?.descriptionCopy && (
            <span
              className={styles.eligibilityNote}
              dangerouslySetInnerHTML={{
                __html: accessCodeConfig.webTemplate.descriptionCopy,
              }}
            />
          )}
          {isCdphp && (
            <span className={styles.eligibilityNote}>
              {str(I18N_STR.cdphpEligNote)}
            </span>
          )}
          {isTarget && <TargetSpanishFlyer />}
        </div>
        {HERO_VERSIONS[heroVersion].heroImage}
      </section>
    </>
  );
}
