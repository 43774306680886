import { getAllUrlParams } from '../app/utils/queryVarsUtils';
import { appHasOrderPlacedSuccessfullyHandler } from '../app/containers/nrt/utils';
import { inferDeviceTypeFromUserAgent } from '../app/utils/mobileDeviceHelper';
import { makeQuestionsAndOptions } from './personalization-quesstions';
import { getLocale } from '../app/i18n/utils';

const urlParams = getAllUrlParams();

export const initialState = {
  urlParams,
  locale: getLocale(),
  headerBarTitle: '',
  fetchState: null,
  message: {
    content: '',
    type: '',
  },
  screenReaderPageTitle: '',
  calledAction: null,
  accessCodeConfig: null,
  altAccessCodeConfig: null,
  userSelections: {
    usage: null,
    personalisation: makeQuestionsAndOptions(),
  },
  userRegistrationData: {
    given_name: '',
    family_name: '',
    email: '',
    password: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    phone_number: '',
    smsOptIn: true,
    birthdate: '',
    gender: '',
    utm_source: urlParams?.utm_source?.substring(0, 50) ?? '',
    utm_campaign: urlParams?.utm_campaign?.substring(0, 50) ?? '',
    activation_code: '',
  },
  userDataForEligibilityReview: {},
  eligibleUserDataFromURLParams: {},
  insuranceType: null,
  emailExistsChecks: {},
  skuFromUrl: urlParams?.sku_name,
  flowType: null,
  requestSmokingHistory: null,
  addressForClaims: {
    fieldPrefix: '_',
    _streetAddress1: '',
    _streetAddress2: '',
    _city: '',
    _state: '',
    _zip: '',
  },
  registrationComplete: false,
  registeredUserId: null,
  nrt: {
    user: {
      userId: null,
      authToken: null,
      address: null,
      shippingAddress: null,
      userDetails: {
        givenName: null,
        familyName: null,
        phone: null,
      },
    },
    healthCondition: '',
    userOrders: null,
    userAvailableNRTPackages: null,
    userTotalNRTOrderCountLimit: null,
    needsOrderSuccessStatus: appHasOrderPlacedSuccessfullyHandler(),
    totalNRTOrderCountLimit: null,
    numUserOrders: null,
    latestOrder: null,
    allowedToOrder: null,
    daysSinceLatestOrderShipped: null,
    readyToOrder: null,
    dataLoaded: false,
  },
  device: inferDeviceTypeFromUserAgent(),
  userCameFromFindEmployer: false,
  loggedInUser: null,
  userProfile: null,
  userMembership: null,
  eligibilitySubmitted: false,
  isVirginPulse: false,
  isPersonifyHealth: false,
  brazeDeviceId: null,
  bspOffer: {
    plan: null,
    isRetail: null,
    products: null,
    age: null,
    acceptedTerms: false,
    hasPromoCode: Boolean(urlParams?.promo_code),
    promoCode: urlParams?.promo_code ?? null,
    stripeCheckoutSession: null,
    isLegalAge: false,
  },
  gotMemberIdFromUrlParam: false,
  gotGroupNumberFromUrlParam: false,
  firstNameFromUrlParam: '',
  isSuspectedBot: undefined,
};
