import { store } from '../../state/store';
import { ACTION_TYPES } from '../../state/actions';

export function fetcher(url, options = {}) {
  store.dispatch({ type: ACTION_TYPES.FETCHING });
  return fetch(url, options)
    .then(res => {
      store.dispatch({ type: ACTION_TYPES.DONE_FETCHING });
      return res;
    })
    .catch(err => {
      store.dispatch({ type: ACTION_TYPES.ERROR_FETCHING });
      return err;
    });
}

export function fetcherAll(requests) {
  store.dispatch({ type: ACTION_TYPES.FETCHING });
  return Promise.all(
    requests.map(({ url, options = {} }) => fetch(url, options))
  )
    .then(res => {
      store.dispatch({ type: ACTION_TYPES.DONE_FETCHING });
      return res;
    })
    .catch(err => {
      store.dispatch({ type: ACTION_TYPES.ERROR_FETCHING });
      return err;
    });
}
