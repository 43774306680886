// @flow

import mixpanel from 'mixpanel-browser';
import isUUID from 'validator/es/lib/isUUID';
import { mixpanelId } from '../../config';
import { getUrlParam } from './queryVarsUtils';
import { getLocale, LANGUAGES } from '../../app/i18n/utils';

export const WEBREG_PREFIX = 'Register2020';
export const NRT_PREFIX = 'NRT';
export const REGISTRATION_COMPLETE = `${WEBREG_PREFIX}:registration-complete`;
export const PASSWORD_CHANGED = `${WEBREG_PREFIX}:password-changed`;
const WEBREG_PAGE_VIEW = `${WEBREG_PREFIX}:page-view`;
const locale = getLocale();
const language =
  locale.languageCode === LANGUAGES.ES ? 'Spanish-Latam' : 'English-US';
const accessCode = getUrlParam('access_code');

mixpanel.init(mixpanelId, {
  record_sessions_percent: accessCode === 'healthpartners' ? 100 : 0,
  record_mask_text_selector: '',
});
mixpanel.register({ language_setting: language });

export function track(event: string, data: any = {}) {
  const eventData = normalizeEventAttributeValues(data);
  mixpanel.track(event, eventData);
}

export function trackAndCallback(
  event: string,
  data: any = {},
  callback = undefined
) {
  const eventData = normalizeEventAttributeValues(data);
  mixpanel.track(event, eventData, callback);
}

export function trackPageViewInMixpanel(page: string, additionalData?: any) {
  identifyUser(getUrlParam('id'));

  const paramOptions: Array<string> = [
    'source',
    'placement',
    'ad_id',
    'adset_id',
    'campaign_id',
    'ad_name',
    'adset_name',
    'campaign_name',
  ];
  let data: Object = {
    'Page Path': page.toLowerCase(),
  };
  for (let i = 0; i <= paramOptions.length; i++) {
    const param = getUrlParam(paramOptions[i]);
    if (param) {
      data[paramOptions[i]] = param;
    }
  }
  if (getUrlParam('id')) {
    data['user_id_from_url'] = getUrlParam('id');
  }
  if (Boolean(additionalData) === true) {
    data = { ...data, ...additionalData };
  }
  track(WEBREG_PAGE_VIEW, data);
}

export function registerAndAliasUserInMixpanel(userSub: string, data: any) {
  registerUnloadEventForMixpanel();
  const personData = makePersonData(data);
  mixpanel.people.set(personData, () => identifyAndReset(userSub));
  track(REGISTRATION_COMPLETE, personData);
}

export function identifyAndReset(userId: string) {
  if (userId && isUUID(userId, 4)) {
    mixpanel.identify(userId, resetUser);
  }
}

export function identifyUser(userId: string) {
  if (userId && isUUID(userId, 4)) {
    mixpanel.identify(userId);
  }
}

export function resetUser() {
  mixpanel.reset();
}

export function registerUnloadEventForMixpanel() {
  window.addEventListener('beforeunload', resetUser);
}

export function trackSignupErrorInMixpanel(
  userSignupData: any,
  errorMessage: string
) {
  track(`${WEBREG_PREFIX}:signup-error`, {
    ...makePersonData(userSignupData),
    error: `Lambda error during signup: ${errorMessage}}`,
  });
}

export function getMixpanelDistinctId() {
  return mixpanel.get_distinct_id();
}

function makePersonData(data) {
  let personData = {};
  for (let key in data.userData) {
    if (data.userData[key].length > 0) {
      personData[`$custom:${key}`] = data.userData[key][0];
    }
  }

  return {
    ...personData,
  };
}

function normalizeEventAttributeValues(eventData) {
  const data = { ...eventData };
  if (data['Page Path']) {
    data['Page Path'] = data['Page Path'].toLowerCase();
  }
  return data;
}
