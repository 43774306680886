import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PARTNERS } from '../../constants';

export function usePartnerConfig() {
  const params = useParams();
  const partnerFromUrl = params?.partner?.toLowerCase();

  const { access_code } = useSelector(state => state.urlParams);
  const accessCodeFromUrl = access_code?.toLowerCase();

  const isTarget =
    PARTNERS.TARGET.accessCodes.includes(accessCodeFromUrl) ||
    partnerFromUrl === PARTNERS.TARGET.alias;

  const isBcnc =
    PARTNERS.BCNC.accessCodes.includes(accessCodeFromUrl) ||
    partnerFromUrl === PARTNERS.BCNC.alias;

  const isCdphp =
    PARTNERS.CDPHP.accessCodes.includes(accessCodeFromUrl) ||
    partnerFromUrl === PARTNERS.CDPHP.alias;

  const isChp = PARTNERS.CHP.accessCodes.includes(accessCodeFromUrl);

  const isLmc =
    PARTNERS.LMC.accessCodes.includes(accessCodeFromUrl) ||
    partnerFromUrl === PARTNERS.LMC.alias;

  const isNyl =
    PARTNERS.NYL.accessCodes.includes(accessCodeFromUrl) ||
    partnerFromUrl === PARTNERS.NYL.alias;

  const isHealthPartners =
    PARTNERS.HEALTHPARTNERS.accessCodes.includes(accessCodeFromUrl) ||
    partnerFromUrl === PARTNERS.HEALTHPARTNERS.alias;

  const isJib =
    PARTNERS.JIB.accessCodes.includes(accessCodeFromUrl) ||
    partnerFromUrl === PARTNERS.JIB.alias;

  const cigOnlyPartners = useMemo(
    () =>
      Object.entries(PARTNERS).reduce((accum, [key, config]) => {
        if (config.isCigOnly) {
          accum.push(config.alias);
          config.accessCodes.forEach(code => {
            accum.push(code);
          });
        }
        return accum;
      }, []),
    [PARTNERS]
  );

  const isCigOnly = useMemo(
    () =>
      cigOnlyPartners
        .filter(Boolean)
        .some(item => item === accessCodeFromUrl || item === partnerFromUrl),
    [cigOnlyPartners]
  );

  return {
    isCigOnly,
    isTarget,
    isBcnc,
    isCdphp,
    isLmc,
    isChp,
    isNyl,
    isHealthPartners,
    isJib,
  };
}
