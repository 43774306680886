import produce from 'immer';
import { applyMiddleware, createStore } from 'redux';
import {
  ACCESS_CODES_FOR_MINORS,
  ADDICTION_TYPES,
  AGE_CATEGORY,
  MESSAGE,
} from '../app/constants';
import { ACTION_TYPES } from './actions';
import { ELIGIBILITY_REGISTRATION_MAP } from './consts';
import { initialState } from './initial-state';
import { getAgeFromDateString } from '../app/utils/validation';
import { generateNumberRange } from '../app/components/option-range-util';

const reducer = (state, action) => {
  return produce(state, draft => {
    draft.calledAction = action.type;
    switch (action.type) {
      case ACTION_TYPES.FETCHING:
      case ACTION_TYPES.DONE_FETCHING:
      case ACTION_TYPES.ERROR_FETCHING:
        draft.fetchState = action.type;
        break;
      case ACTION_TYPES.SET_MESSAGE:
        draft.message = {
          content: action.payload.message,
          type: Boolean(action.payload.type)
            ? action.payload.type
            : MESSAGE.TYPE.INFO,
        };
        setTimeout(() => {
          store.dispatch({
            type: ACTION_TYPES.CLEAR_MESSAGE,
          });
        }, 5000);
        break;
      case ACTION_TYPES.CLEAR_MESSAGE:
        draft.message = null;
        break;
      case ACTION_TYPES.SET_ACCESS_CODE_CONFIG: {
        // TCP-1384: Manually inject ageCategory to access code config
        const ageCategory = ACCESS_CODES_FOR_MINORS.includes(
          action.payload.data.accessCode.toLowerCase()
        )
          ? AGE_CATEGORY.MINOR
          : AGE_CATEGORY.ADULT;
        const configPayload = {
          ...action.payload.data,
          ageCategory,
        };

        if (action.payload.status === 'success') {
          draft.accessCodeConfig = {
            ...state.accessCodeConfig,
            ...configPayload,
            webTemplate: {
              availabilityCopy: configPayload.webTemplate?.availability_copy,
              descriptionCopy: configPayload.webTemplate?.description_copy,
              helptextCopy: configPayload.webTemplate?.helptext_copy,
              logoUrl: configPayload.webTemplate?.logo_url,
              moreInfoCopy: configPayload.webTemplate?.more_info_copy,
              orgAlias: configPayload.webTemplate?.org_alias,
              uniqueIdTextboxPlaceholderCopy:
                configPayload.webTemplate?.uniqueid_textbox_placeholder_copy,
            },
            ...(state.accessCodeConfig?.alias &
              { alias: state.accessCodeConfig?.alias }),
          };
          draft.accessCodeConfig.accessCode =
            configPayload.accessCode.toLowerCase();
          const availableNicotineAddictionTypes = Object.keys(
            configPayload.availableNicotineAddictionTypes
          );
          draft.userSelections.usage = Object.keys(ADDICTION_TYPES).reduce(
            (accumulator, currentValue) => {
              const item = ADDICTION_TYPES[currentValue];
              accumulator.push({
                key: item,
                selected: false,
                enabled: availableNicotineAddictionTypes.includes(item),
              });
              return accumulator;
            },
            []
          );
        }

        if (action.payload.status === 'fail') {
          draft.message = configPayload.message;
        }
        break;
      }
      case ACTION_TYPES.SET_ALT_ACCESS_CODE_CONFIG: {
        // TCP-1384: Manually inject ageCategory to access code config
        const ageCategory = ACCESS_CODES_FOR_MINORS.includes(
          action.payload.data.accessCode.toLowerCase()
        )
          ? AGE_CATEGORY.MINOR
          : AGE_CATEGORY.ADULT;
        const configPayload = {
          ...action.payload.data,
          ageCategory,
        };

        if (action.payload.status === 'success') {
          draft.altAccessCodeConfig = {
            ...state.altAccessCodeConfig,
            ...configPayload,
            webTemplate: {
              availabilityCopy: configPayload.webTemplate?.availability_copy,
              descriptionCopy: configPayload.webTemplate?.description_copy,
              helptextCopy: configPayload.webTemplate?.helptext_copy,
              logoUrl: configPayload.webTemplate?.logo_url,
              moreInfoCopy: configPayload.webTemplate?.more_info_copy,
              orgAlias: configPayload.webTemplate?.org_alias,
              uniqueIdTextboxPlaceholderCopy:
                configPayload.webTemplate?.uniqueid_textbox_placeholder_copy,
            },
            ...(state.altAccessCodeConfig?.alias &
              { alias: state.altAccessCodeConfig?.alias }),
          };
          draft.altAccessCodeConfig.accessCode =
            configPayload.accessCode.toLowerCase();
        }

        if (action.payload.status === 'fail') {
          draft.message = configPayload.message;
        }
        break;
      }
      case ACTION_TYPES.SWAP_ALT_ACCESS_CODE_CONFIG: {
        draft.accessCodeConfig = { ...state.altAccessCodeConfig };
        break;
      }
      case ACTION_TYPES.SET_ORG_CONFIG:
        draft.accessCodeConfig = {
          ...state.accessCodeConfig,
          webTemplate: action.payload.webTemplate,
          alias: action.payload.alias,
        };
        break;
      case ACTION_TYPES.POPULATE_BSP_OFFER_USAGE_TYPES:
        /*We also preselect cig as this experiment is cig only and doesn't have nic type selection*/
        draft.userSelections.usage = Object.keys(ADDICTION_TYPES).reduce(
          (accumulator, currentValue) => {
            const item = ADDICTION_TYPES[currentValue];
            accumulator.push({
              key: item,
              selected: action.payload === item,
              enabled: action.payload === item,
            });
            return accumulator;
          },
          []
        );
        break;
      case ACTION_TYPES.SELECT_USAGE_TYPE:
        draft.userSelections.usage = draft.userSelections.usage.map(item => {
          if (item.key === action.payload) {
            item.selected = !item.selected;
          }
          if (item.key === ADDICTION_TYPES.NONE) {
            item.selected = false;
          }
          return item;
        });
        break;
      case ACTION_TYPES.SELECT_NONE_USAGE_TYPE:
        draft.userSelections.usage = draft.userSelections.usage.map(item => {
          item.selected = item.key === action.payload;
          return item;
        });
        break;
      case ACTION_TYPES.RESET_USAGE_TYPE:
        draft.userSelections.usage = draft.userSelections.usage.map(item => {
          item.selected = false;
          return item;
        });
        break;
      case ACTION_TYPES.SELECT_BSP_OFFER_USAGE_TYPE:
        draft.userSelections.usage = draft.userSelections.usage.map(item => {
          if (action.payload === ADDICTION_TYPES.NONE) {
            item.selected = item.key === ADDICTION_TYPES.NONE;
          } else {
            if (item.key === action.payload) {
              item.selected = !item.selected;
            }
            if (item.key === ADDICTION_TYPES.NONE) {
              item.selected = false;
            }
          }
          if (item.key === ADDICTION_TYPES.SMOKING) {
            item.selected = true;
          }
          return item;
        });
        break;
      case ACTION_TYPES.SELECT_ONE_PERSONALIZATION_ITEM:
        draft.userSelections.personalisation[action.payload.name] =
          draft.userSelections.personalisation[action.payload.name].map(
            item => {
              if (action.payload.value === item.key) {
                item.selected = !item.selected;
              } else {
                item.selected = false;
              }
              return item;
            }
          );
        break;
      case ACTION_TYPES.SELECT_DOB:
        {
          //draft.userRegistrationData.birthdate = action.payload.value; // only thing needed if we don't need dtc
          draft.userRegistrationData.birthdate = action.payload.value;
          const isAgeStartedSmokingSelected =
            state.userSelections.personalisation.ageStartedSmoking.some(
              item => item.selected
            );

          if (!isAgeStartedSmokingSelected) {
            draft.userSelections.personalisation.ageStartedSmoking =
              generateNumberRange(
                1,
                getAgeFromDateString(action.payload.value),
                1,
                0
              ).map(item => ({ key: item, label: item, selected: false }));
          }
        }
        break;
      case ACTION_TYPES.SAVE_USER_DATA_FOR_ELIGIBILITY_REVIEW:
        draft.userDataForEligibilityReview = {
          ...state.userDataForEligibilityReview,
          ...action.payload,
        };
        Object.keys(action.payload).forEach(key => {
          const userRegKey = ELIGIBILITY_REGISTRATION_MAP[key];
          draft.userRegistrationData[userRegKey] = action.payload[key];
        });
        break;
      case ACTION_TYPES.SET_USER_REGISTRATION_FIELD:
        draft.userRegistrationData[action.payload.name] = action.payload.value;
        break;
      case ACTION_TYPES.SET_USER_SHIPPING_DATA_FIELD:
        draft.addressForClaims[action.payload.name] = action.payload.value;
        break;
      case ACTION_TYPES.SET_ELIGIBILITY_CASE_ID:
        draft.eligibilityCaseId = action.payload;
        break;
      case ACTION_TYPES.SET_ELIGIBLE_USER_DATA_FROM_URL_PARAMS:
        draft.eligibleUserDataFromURLParams = action.payload;
        break;
      case ACTION_TYPES.UPDATE_HEADER_TITLE:
        draft.headerBarTitle = action.payload;
        break;
      case ACTION_TYPES.SET_FLOW_TYPE:
        draft.flowType = action.payload;
        break;
      case ACTION_TYPES.REQUEST_SMOKING_HISTORY:
        draft.requestSmokingHistory = true;
        break;
      case ACTION_TYPES.SET_INSURANCE_TYPE:
        draft.insuranceType = action.payload;
        break;
      case ACTION_TYPES.SET_SCREEN_READER_PAGE_TITLE:
        draft.screenReaderPageTitle = action.payload;
        break;
      case ACTION_TYPES.SET_REGISTRATION_COMPLETE:
        draft.registrationComplete = true;
        break;
      case ACTION_TYPES.SET_REGISTERED_USER_ID:
        draft.registeredUserId = action.payload;
        break;
      case ACTION_TYPES.NRT_SET_DEVICE:
        draft.device = action.payload;
        break;
      case ACTION_TYPES.NRT_SET_INITIAL_DATA:
        draft.nrt.dataLoaded = true;
        draft.nrt.userOrders = action.payload.userOrders;
        draft.nrt.userAvailableNRTPackages =
          action.payload.userAvailableNRTPackages;
        draft.nrt.userTotalNRTOrderCountLimit =
          action.payload.userTotalNRTOrderCountLimit;
        draft.nrt.totalNRTOrderCountLimit =
          action.payload.totalNRTOrderCountLimit;
        draft.nrt.numUserOrders = action.payload.numUserOrders;
        draft.nrt.latestOrder = action.payload.latestOrder;
        draft.nrt.allowedToOrder = action.payload.allowedToOrder;
        draft.nrt.daysSinceLatestOrderShipped =
          action.payload.daysSinceLatestOrderShipped;
        draft.nrt.readyToOrder = action.payload.readyToOrder;
        draft.nrt.user.shippingAddress = action.payload.shippingAddress;
        break;
      case ACTION_TYPES.NRT_SET_USER:
        draft.nrt.user = action.payload;
        break;
      case ACTION_TYPES.NRT_SET_HEALTH_CONDITION:
        draft.nrt.healthCondition = action.payload;
        break;
      case ACTION_TYPES.NRT_SET_COMPLETED_ORDER:
        draft.nrt.numUserOrders = state.nrt.numUserOrders + 1;
        draft.nrt.allowedToOrder = false;
        draft.nrt.daysSinceLatestOrderShipped = null;
        draft.nrt.readyToOrder = false;
        break;
      case ACTION_TYPES.NRT_SET_OPTION_SELECTION: {
        const productOfferings =
          state.nrt.userAvailableNRTPackages.productOfferings;
        draft.nrt.userAvailableNRTPackages.productOfferings =
          productOfferings.map(product => {
            return {
              ...product,
              selected: product.item === action.payload,
            };
          });
        break;
      }
      case ACTION_TYPES.NRT_SET_DOSAGE_SELECTION: {
        const { productOfferings } = state.nrt.userAvailableNRTPackages;
        draft.nrt.userAvailableNRTPackages.productOfferings =
          productOfferings.map(product => {
            const productOptions = product.options.map(option1 => {
              // isGumOnly is needed because the options are nested, this allows the user to have flavors initially not selected
              const isGumOnly = product.item.toLowerCase().includes('gum only');
              const options = option1.options.map(option2 => {
                return {
                  ...option2,
                  selected: !isGumOnly && option1.item === action.payload,
                };
              });
              return {
                ...option1,
                options,
                selected: option1.item === action.payload,
              };
            });
            return {
              ...product,
              options: productOptions,
            };
          });
        break;
      }
      case ACTION_TYPES.NRT_SET_FLAVOR_SELECTION: {
        const { productOfferings } = state.nrt.userAvailableNRTPackages;
        draft.nrt.userAvailableNRTPackages.productOfferings =
          productOfferings.map(product => {
            const productOptions = product.options.map(option => {
              const flavors = option.options.map(flavor => {
                return { ...flavor, selected: flavor.item === action.payload };
              });
              return { ...option, options: flavors };
            });
            return {
              ...product,
              options: productOptions,
            };
          });
        break;
      }
      case ACTION_TYPES.NRT_SET_BUNDLE_FLAVOR_SELECTION: {
        const { productOfferings } = state.nrt.userAvailableNRTPackages;
        draft.nrt.userAvailableNRTPackages.productOfferings =
          productOfferings.map(product => {
            const productOptions = product.options.map(option1 => {
              const options = option1.options.map(option2 => {
                const flavors =
                  Boolean(option2?.options) &&
                  option2?.options.map(flavor => {
                    return {
                      ...flavor,
                      selected: flavor.item === action.payload,
                    };
                  });
                return { ...option2, ...(flavors && { options: flavors }) };
              });
              return { ...option1, options };
            });
            return {
              ...product,
              options: productOptions,
            };
          });
        break;
      }
      case ACTION_TYPES.USER_CAME_FROM_FIND_EMPLOYER:
        draft.userCameFromFindEmployer = true;
        break;
      case ACTION_TYPES.SET_LOGGED_IN_USER:
        draft.loggedInUser = action.payload;
        break;
      case ACTION_TYPES.SET_USER_PROFILE:
        draft.userProfile = action.payload;
        break;
      case ACTION_TYPES.SET_USER_MEMBERSHIP:
        draft.userMembership = action.payload;
        break;
      case ACTION_TYPES.ELIGIBILITY_SUBMITTED:
        draft.eligibilitySubmitted = true;
        break;
      case ACTION_TYPES.SAVE_EMAIL_EXISTS_CHECK:
        const { email, status } = action.payload;
        draft.emailExistsChecks = {
          ...state.emailExistsChecks,
          [email]: status,
        };
        break;
      case ACTION_TYPES.SET_IS_VIRGIN_PULSE:
        draft.isVirginPulse = true;
        break;
      case ACTION_TYPES.SET_IS_PERSONIFY_HEALTH:
        draft.isPersonifyHealth = true;
        break;
      case ACTION_TYPES.SET_BRAZE_DEVICE_ID:
        draft.brazeDeviceId = action.payload;
        break;
      case ACTION_TYPES.SET_BSP_OFFER_PRODUCTS:
        draft.bspOffer.products = action.payload;
        break;
      case ACTION_TYPES.SET_BSP_OFFER_IS_RETAIL:
        draft.bspOffer.isRetail = action.payload;
        break;
      case ACTION_TYPES.SELECT_BSP_OFFER_PLAN:
        draft.bspOffer.plan = action.payload;
        break;
      case ACTION_TYPES.SET_BSP_OFFER_DATA:
        draft.bspOffer[action.payload.name] = action.payload.value;
        break;
      case ACTION_TYPES.SET_BSP_OFFER_PROMO_CODE:
        draft.bspOffer.promoCode = action.payload.value;
        break;
      case ACTION_TYPES.SET_BSP_OFFER_STRIPE_CHECKOUT_SESSION:
        draft.bspOffer.stripeCheckoutSession = action.payload;
        break;
      case ACTION_TYPES.TOGGLE_BSP_OFFER_HAS_PROMO_CODE:
        draft.bspOffer.hasPromoCode = !state.bspOffer.hasPromoCode;
        break;
      case ACTION_TYPES.TOGGLE_BSP_OFFER_IS_LEGAL_AGE:
        draft.bspOffer.isLegalAge = !state.bspOffer.isLegalAge;
        break;
      case ACTION_TYPES.SET_GOT_MEMBER_ID_FROM_URL_PARAM:
        draft.gotMemberIdFromUrlParam = action.payload;
        break;
      case ACTION_TYPES.SET_GOT_GROUP_NUMBER_FROM_URL_PARAM:
        draft.gotGroupNumberFromUrlParam = action.payload;
        break;
      case ACTION_TYPES.SET_GOT_ZIP_CODE_FROM_URL_PARAM:
        draft.gotZipCodeFromUrlParam = action.payload;
        break;
      case ACTION_TYPES.SET_GOT_DATE_OF_BIRTH_FROM_URL_PARAM:
        draft.gotDateOfBirthFromUrlParam = action.payload;
        break;
      case ACTION_TYPES.SET_FIRST_NAME_FROM_URL_PARAM:
        draft.firstNameFromUrlParam = action.payload;
        break;
      case ACTION_TYPES.SET_IS_SUSPECTED_BOT:
        draft.isSuspectedBot = action.payload;
        break;
      default:
        break;
    }
  });
};

const loggingMiddleware = store => next => action => {
  if (window.IS_SHOWING_LOGS) {
    console.log(
      `%cRedux`,
      'background: #00b188; color: #fff; padding:2px;',
      action
    );
  }
  next(action);
};

export const store = isProduction()
  ? createStore(reducer, initialState)
  : createStore(reducer, initialState, applyMiddleware(loggingMiddleware));

const handleChange = () => {
  if (window.IS_SHOWING_LOGS) {
    const state = store.getState();
    console.log(
      `%cState`,
      'background: #5c99cb; color: #fff; padding:2px;',
      state
    );
  }
};

if (!isProduction()) {
  store.subscribe(handleChange);
  console.log(
    `%cTo enable logging run \'window.showLogs()\' in the browser's console`,
    'background: #FEB400; color: #000; padding:2px;'
  );
}

function isProduction() {
  return process.env.REACT_APP_ENV === 'production';
}

window.IS_SHOWING_LOGS = false;

window.showLogs = () => {
  window.IS_SHOWING_LOGS = true;
  store.subscribe(handleChange);
  console.log(
    `%cLogging enabled`,
    'background: #4a50b2; color: #fff; padding:2px;'
  );
};
