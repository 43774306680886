import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ADDICTION_TYPES } from '../../../constants';
import { trackAndCallback, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import {
  logBrazeEvent,
  setBrazeAttribute,
  BRAZE_EVENTS,
  BRAZE_ATTRIBUTES,
} from '../../../utils/brazeHelper';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';
import { PAGES } from '../../../page-urls';

export const NIC_TYPE_PARAM = 'nt';
const ACCESS_CODE_PARAM = 'access_code';

export function getNicTypeFromUrlParam() {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  return params.get(NIC_TYPE_PARAM) || ADDICTION_TYPES.SMOKING;
}

export default function useNicTypeValues() {
  const location = useLocation();
  const isPersonifyHealth = useSelector(state => state.isPersonifyHealth);
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  let params = new URLSearchParams(location.search);
  const currentNicTypeParam = getNicTypeFromUrlParam();
  const { str } = useI18n();

  const LANDING_PAGE_NIC_TYPES = {
    [ADDICTION_TYPES.SMOKING]: {
      title: str(I18N_STR.pivotForSmoking),
      linkLabel: str(I18N_STR.pivotForSmoking),
    },
    [ADDICTION_TYPES.VAPING]: {
      title: str(I18N_STR.pivotForVaping),
      linkLabel: str(I18N_STR.pivotForVaping),
    },
    [ADDICTION_TYPES.OTHER]: {
      title: str(I18N_STR.pivotForOther),
      linkLabel: str(I18N_STR.pivotForOtherLinkLabel),
    },
  };

  const values = useMemo(() => {
    const currentNicType = LANDING_PAGE_NIC_TYPES[currentNicTypeParam];
    let otherNicTypes = [];

    for (const [key, value] of Object.entries(LANDING_PAGE_NIC_TYPES)) {
      if (currentNicTypeParam === key) {
        continue;
      }

      params.set(NIC_TYPE_PARAM, key);
      if (!params.has(ACCESS_CODE_PARAM) && accessCodeConfig?.accessCode) {
        params.set(ACCESS_CODE_PARAM, accessCodeConfig.accessCode);
      }

      const prefix = isPersonifyHealth
        ? PAGES.PERSONIFY_HEALTH_LANDING.url
        : PAGES.PARTNER_LANDING.url;
      const pathname = accessCodeConfig?.webTemplate?.orgAlias
        ? `${prefix}/${accessCodeConfig.webTemplate.orgAlias}`
        : '';

      otherNicTypes.push({
        key,
        url: `${pathname}?${params}`,
        linkLabel: LANDING_PAGE_NIC_TYPES[key]['linkLabel'],
      });
    }

    return {
      otherNicTypes,
      current: {
        nicType: currentNicTypeParam,
        title: currentNicType.title,
      },
    };
  }, [location, accessCodeConfig]);

  const trackAndNavigateToOtherNicType = (nicType, url) => {
    logBrazeEvent(BRAZE_EVENTS.CLICKED_NICOTINE_TYPE, {
      type: nicType,
    });
    setBrazeAttribute(BRAZE_ATTRIBUTES.NICOTINE_TYPE_CLICKS, nicType, true);

    trackAndCallback(
      `${WEBREG_PREFIX}:switch-nic-type`,
      {
        'Page Path': location.pathname,
        accessCode: params.get(ACCESS_CODE_PARAM),
        from: values.current.nicType,
        to: nicType,
      },
      () => {
        window.location.replace(url);
      }
    );
  };

  return {
    otherNicTypes: values.otherNicTypes,
    current: values.current,
    trackAndNavigateToOtherNicType,
  };
}
