import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import TargetReasonableAlternative from './landing-content-bands/target-reasonable-alternative';
import TargetSpanishFlyer from './landing-content-bands/target-spanish-flyer';
import FixedHeader from './common-components/fixed-header';
import Hero from './landing-content-bands/hero';
import { ACTION_TYPES } from '../../../state/actions';
import { AccessCodeSubmissionTracker } from './common-utils/AccessCodeSubmissionTracker';
import {
  getAccessCodeConfig,
  getOrgConfig,
} from './common-utils/dynamicRegistrationHelper';
import {
  ACCESS_CODES_API_RES_TYPES,
  ADDICTION_TYPES,
  PARTNERS,
} from '../../constants';
import {
  dispatchAccessCodeExpired,
  dispatchAccessCodeFetchFail,
  dispatchAccessCodeFetchSuccess,
  dispatchAltAccessCodeFetchSuccess,
  isAccessCodeActiveByDate,
} from './common-utils/AccessCodeUtils';
import { useRegistrationNavigator } from './use-registration-navigator';
import { PAGES } from '../../page-urls';
import { useVirginPulseAuth } from './virgin-pulse/useVirginPulse';
import VirginPulseThirdPartyCookiesModal from './modals/VirginPulseThirdPartyCookiesModal';
import VirginPulseAuthenticateModal from './modals/VirginPulseAuthenticateModal';
import VirginPulseEmailExistsModal from './modals/VirginPulseEmailExistsModal';
import {
  PH_AUTH_STATES,
  usePersonifyHealthAuth,
} from './personify-health/usePersonifyHealth';
import PersonifyHealthThirdPartyCookiesModal from './modals/PersonifyHealthThirdPartyCookiesModal';
import PersonifyHealthAuthenticateModal from './modals/PersonifyHealthAuthenticateModal';
import PersonifyHealthEmailExistsModal from './modals/PersonifyHealthEmailExistsModal';
import {
  logBrazeEvent,
  setBrazeAlias,
  setBrazeAttribute,
  setDeviceBrazeAlias,
  mergeBrazeProfiles,
  BRAZE_ATTRIBUTES,
  BRAZE_EVENTS,
  BRAZE_IDENTIFIERS,
} from '../../utils/brazeHelper';
import { getNicTypeFromUrlParam } from './common-utils/useNicTypeValues';
import { usePartnerConfig } from './usePartnerConfig';
import styles from '../../../css/UnifiedLanding.module.scss';
import { ELIG_KEY_NAMES, ELIGIBILITY_FIELDS } from './eligibility/utils';
import StepsProgressBar from './common-components/steps-progress-bar';
import Wrapper from './common-components/Wrapper';
import FormFooter from './common-components/FormFooter';
import classnames from 'classnames';
import { HeaderLogo } from '../../components/HeaderLogo';
import AboutBreatheImg from '../../../media/about-breathe.webp';
import { useI18n } from '../../i18n/utils';
import { I18N_STR } from '../../i18n/strings';

const SectionAppSensor = React.lazy(
  () => import('./landing-content-bands/section-app-sensor')
);
const TestimonialsSlider = React.lazy(
  () => import('./landing-content-bands/testimonials-slider')
);
const SectionQuitKit = React.lazy(
  () => import('./landing-content-bands/section-quitkit')
);
const SectionCoreLoop = React.lazy(
  () => import('./landing-content-bands/section-coreloop')
);
const SectionNrt = React.lazy(
  () => import('./landing-content-bands/section-nrt')
);
const SectionCoaching = React.lazy(
  () => import('./landing-content-bands/section-coaching')
);
const SectionVapeCravings = React.lazy(
  () => import('./landing-content-bands/section-vape-cravings')
);
const SectionVapeCoaching = React.lazy(
  () => import('./landing-content-bands/section-vape-coaching')
);
const SectionVapePace = React.lazy(
  () => import('./landing-content-bands/section-vape-pace')
);
const SectionVapeQuitKit = React.lazy(
  () => import('./landing-content-bands/section-vape-quitkit')
);
const SectionVapeFun = React.lazy(
  () => import('./landing-content-bands/section-vape-fun')
);
const SectionOtherDiscover = React.lazy(
  () => import('./landing-content-bands/section-other-discover')
);
const SectionOtherCoaching = React.lazy(
  () => import('./landing-content-bands/section-other-coaching')
);
const SectionOtherCommunity = React.lazy(
  () => import('./landing-content-bands/section-other-community')
);
const PrivacyPolicy = React.lazy(
  () => import('./landing-content-bands/privacy-policy')
);
const BcncLegalFooter = React.lazy(
  () => import('./landing-content-bands/bcnc-legal-footer')
);
const JibLegalFooter = React.lazy(
  () => import('./landing-content-bands/jib-legal-footer')
);
const InteractiveCTA = React.lazy(
  () => import('./landing-content-bands/band-interactive-cta')
);
const ExitIntentModalInteractiveCTA = React.lazy(
  () => import('./modals/ExitIntentModalInteractiveCTA')
);
const AccessCodeNotYetOpenModal = React.lazy(
  () => import('./modals/AccessCodeNotYetOpenModal')
);
const MessageModal = React.lazy(() => import('./modals/MessageModal'));

export default function LandingPage({ isStreamlined = false }) {
  const { str } = useI18n();
  const { vpAuthState } = useVirginPulseAuth();
  const { phAuthState } = usePersonifyHealthAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { navigator } = useRegistrationNavigator();
  const isVirginPulse = useSelector(state => state.isVirginPulse);
  const isPersonifyHealth = useSelector(state => state.isPersonifyHealth);
  const [accessCodeCheckRes, setAccessCodeCheckRes] = useState();
  const [isAccessCodeModalOpen, setIsAccessCodeModalOpen] = useState();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState();
  const [showAuthenticationModal, setShowAuthenticationModal] = useState(false);
  const urlParams = useSelector(state => state.urlParams);
  const {
    x: emailParam,
    m: memberIdParam,
    g: groupNumberParam,
    z: zipCodeParam,
    b: dateOfBirthParam,
    f: firstNameParam,
    access_code: accessCodeFromUrl,
    mid: marketingId,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
    utm_term: utmTerm,
    lp: abTest,
    ref_id: refId,
    msg: messageParam,
  } = urlParams;
  const { isTarget, isBcnc, isCdphp, isHealthPartners, isJib } =
    usePartnerConfig();

  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const altAccessCodeConfig = useSelector(state => state.altAccessCodeConfig);

  const accessCode =
    accessCodeFromUrl?.toLowerCase() ||
    (isTarget && PARTNERS.TARGET.accessCodes[0]) ||
    (isBcnc && PARTNERS.BCNC.accessCodes[0]) ||
    (isCdphp && PARTNERS.CDPHP.accessCodes[0]) ||
    null;

  // BCNC needs a single landing page that can differentiate which access code to use based on eligibility.
  // BCNC is composed of the fully-insured and ASO groups, which need access codes bluecrossnc and bluecrossncaso
  // respectively for tracking purposes.
  // We try eligibility on each organization (access code) and check for a response that they are eligible.
  // If more organizations need this feature in the future, we will need a better approach than this one.
  const altAccessCode = isBcnc ? 'bluecrossncaso' : null;

  const nicType = getNicTypeFromUrlParam();

  // For the streamlined flow, we use a simplified landing page and no eligibility intro page
  const isStreamlinedFlow = isStreamlined || abTest === '1';

  const processEmailParam = () => {
    // decode and save email param
    if (emailParam) {
      let email;
      try {
        email = atob(emailParam);
      } catch (err) {
        // invalid base64 string
      }
      dispatch({
        type: ACTION_TYPES.SET_USER_REGISTRATION_FIELD,
        payload: { name: 'email', value: email },
      });
    }
  };

  const processMemberIdParam = () => {
    // decode, validate and save Member ID param
    if (memberIdParam) {
      let memberId;
      try {
        memberId = atob(memberIdParam);
      } catch (err) {
        // invalid base64 string
        return;
      }
      let memberIdEligibilityField = 'memberId';
      if (isCdphp) {
        memberIdEligibilityField = 'memberIdForCDPHP';
      } else if (isHealthPartners) {
        memberIdEligibilityField = 'memberIdForHealthPartners';
      }
      const { validator } = ELIGIBILITY_FIELDS[memberIdEligibilityField];
      if (validator(memberId)) {
        dispatch({
          type: ACTION_TYPES.SET_USER_REGISTRATION_FIELD,
          payload: { name: ELIG_KEY_NAMES.MEMBER_ID, value: memberId },
        });
        dispatch({
          type: ACTION_TYPES.SET_GOT_MEMBER_ID_FROM_URL_PARAM,
          payload: true,
        });
      }
    }
  };

  const processGroupNumberParam = () => {
    // decode, validate and save Group Number param
    if (groupNumberParam) {
      let groupNumber;
      try {
        groupNumber = atob(groupNumberParam);
      } catch (err) {
        // invalid base64 string
        return;
      }
      const { validator } = ELIGIBILITY_FIELDS['groupNumber'];
      if (validator(groupNumber)) {
        dispatch({
          type: ACTION_TYPES.SET_USER_REGISTRATION_FIELD,
          payload: { name: ELIG_KEY_NAMES.GROUP_NUMBER, value: groupNumber },
        });
        dispatch({
          type: ACTION_TYPES.SET_GOT_GROUP_NUMBER_FROM_URL_PARAM,
          payload: true,
        });
      }
    }
  };

  const processZipCodeParam = () => {
    // decode, validate and save Zip Code param
    if (zipCodeParam) {
      let zipCode;
      try {
        zipCode = atob(zipCodeParam);
      } catch (err) {
        // invalid base64 string
        return;
      }
      const { validator } = ELIGIBILITY_FIELDS['zipCode'];
      if (validator(zipCode)) {
        dispatch({
          type: ACTION_TYPES.SET_USER_REGISTRATION_FIELD,
          payload: { name: ELIG_KEY_NAMES.ZIP, value: zipCode },
        });
        dispatch({
          type: ACTION_TYPES.SET_GOT_ZIP_CODE_FROM_URL_PARAM,
          payload: true,
        });
      }
    }
  };

  const processDateOfBirthParam = () => {
    // decode, validate and save Date of Birth param
    if (dateOfBirthParam) {
      let dateOfBirth;
      try {
        dateOfBirth = atob(dateOfBirthParam);
      } catch (err) {
        // invalid base64 string
        return;
      }
      const { validator } = ELIGIBILITY_FIELDS['dateOfBirth'];
      if (validator(dateOfBirth)) {
        dispatch({
          type: ACTION_TYPES.SET_USER_REGISTRATION_FIELD,
          payload: { name: ELIG_KEY_NAMES.DOB, value: dateOfBirth },
        });
        dispatch({
          type: ACTION_TYPES.SET_GOT_DATE_OF_BIRTH_FROM_URL_PARAM,
          payload: true,
        });
      }
    }
  };

  const processFirstNameParam = () => {
    if (firstNameParam) {
      let firstName;
      try {
        firstName = atob(firstNameParam);
      } catch (err) {
        // invalid base64 string
        return;
      }
      dispatch({
        type: ACTION_TYPES.SET_FIRST_NAME_FROM_URL_PARAM,
        payload: firstName,
      });
    }
  };

  const processMessageParam = () => {
    if (messageParam === 'snooze') {
      setIsMessageModalOpen(true);
      logBrazeEvent(BRAZE_EVENTS.SNOOZED);
    }
    if (messageParam === 'snoozeOnboard') {
      setIsMessageModalOpen(true);
      logBrazeEvent(BRAZE_EVENTS.SNOOZED_ONBOARD);
    }
  };

  useEffect(() => {
    processEmailParam();
    processMemberIdParam();
    processGroupNumberParam();
    processZipCodeParam();
    processDateOfBirthParam();
    processMessageParam();
    processFirstNameParam();
  }, []);

  useEffect(() => {
    setDeviceBrazeAlias();

    if (marketingId) {
      const aliasName = BRAZE_IDENTIFIERS.MARKETING_ID;
      mergeBrazeProfiles(aliasName, marketingId, aliasName, () => {
        setBrazeAlias(aliasName, marketingId);
      });
    }
    if (accessCode) {
      setBrazeAttribute(BRAZE_ATTRIBUTES.ACCESS_CODE, accessCode);
    }
    if (utmSource) {
      setBrazeAttribute(BRAZE_ATTRIBUTES.UTM_SOURCE, utmSource, true);
    }
    if (utmMedium) {
      setBrazeAttribute(BRAZE_ATTRIBUTES.UTM_MEDIUM, utmMedium, true);
    }
    if (utmCampaign) {
      setBrazeAttribute(BRAZE_ATTRIBUTES.UTM_CAMPAIGN, utmCampaign, true);
    }
    if (utmContent) {
      setBrazeAttribute(BRAZE_ATTRIBUTES.UTM_CONTENT, utmContent, true);
    }
    if (utmTerm) {
      setBrazeAttribute(BRAZE_ATTRIBUTES.UTM_TERM, utmTerm, true);
    }
    if (refId) {
      setBrazeAttribute(BRAZE_ATTRIBUTES.REF_ID, refId);
    }
    setBrazeAttribute(BRAZE_ATTRIBUTES.URL_PARAMS, urlParams, false);
  }, [marketingId]);

  useEffect(() => {
    if (!accessCode && Boolean(params.partner)) {
      getOrgConfig(params.partner.toLowerCase())
        .then(res => {
          if (res.status === 'success') {
            dispatch({
              type: ACTION_TYPES.SET_ORG_CONFIG,
              payload: {
                webTemplate: res.data.webTemplate,
                alias: params.partner,
              },
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

    // check access code on load
    if (accessCode) {
      const tracker = new AccessCodeSubmissionTracker(
        accessCode,
        location,
        Boolean(accessCode)
      );
      tracker.trackInitiated();

      getAccessCodeConfig(accessCode)
        .then(res => {
          setAccessCodeCheckRes(res);
          if (res.status === ACCESS_CODES_API_RES_TYPES.SUCCESS) {
            dispatchAccessCodeFetchSuccess(res);
            switch (res.data.status) {
              case ACCESS_CODES_API_RES_TYPES.EXPIRED:
                tracker.trackExpired();
                break;
              case ACCESS_CODES_API_RES_TYPES.FULL:
                tracker.trackFull();
                break;
              default:
                const { startTime, endTime } = res.data;
                if (isAccessCodeActiveByDate(startTime, endTime)) {
                  tracker.trackValid();
                } else {
                  tracker.trackNotStarted();
                }
                break;
            }

            if (Boolean(params.partner)) {
              getOrgConfig(params.partner.toLowerCase())
                .then(res => {
                  if (res.status === 'success') {
                    dispatch({
                      type: ACTION_TYPES.SET_ORG_CONFIG,
                      payload: {
                        webTemplate: res.data.webTemplate,
                        alias: params.partner,
                      },
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            }
          }

          if (res.status === ACCESS_CODES_API_RES_TYPES.FAIL) {
            tracker.trackFail();
          }
        })
        .catch(() => {
          tracker.trackServerError();
        });
    }

    if (altAccessCode) {
      const tracker = new AccessCodeSubmissionTracker(
        altAccessCode,
        location,
        Boolean(altAccessCode)
      );
      tracker.trackInitiated();

      getAccessCodeConfig(altAccessCode)
        .then(res => {
          if (res.status === ACCESS_CODES_API_RES_TYPES.SUCCESS) {
            dispatchAltAccessCodeFetchSuccess(res);
            switch (res.data.status) {
              case ACCESS_CODES_API_RES_TYPES.EXPIRED:
                tracker.trackExpired();
                break;
              case ACCESS_CODES_API_RES_TYPES.FULL:
                tracker.trackFull();
                break;
              default:
                const { startTime, endTime } = res.data;
                if (isAccessCodeActiveByDate(startTime, endTime)) {
                  tracker.trackValid();
                } else {
                  tracker.trackNotStarted();
                }
                break;
            }
          }

          if (res.status === ACCESS_CODES_API_RES_TYPES.FAIL) {
            tracker.trackFail();
          }
        })
        .catch(() => {
          tracker.trackServerError();
        });
    }
  }, []);

  const getStarted = () => {
    logBrazeEvent(BRAZE_EVENTS.CLICKED_CTA);

    if (!accessCode) {
      navigate(PAGES.COVERAGE.url);
      return;
    }

    if (accessCodeCheckRes?.status === ACCESS_CODES_API_RES_TYPES.SUCCESS) {
      const { startTime, endTime, status } = accessCodeCheckRes.data;
      switch (status) {
        case ACCESS_CODES_API_RES_TYPES.EXPIRED:
          dispatchAccessCodeExpired();
          break;
        case ACCESS_CODES_API_RES_TYPES.FULL:
          navigator.gotoShortlistPage(accessCode);
          break;
        case ACCESS_CODES_API_RES_TYPES.NOT_STARTED:
          setIsAccessCodeModalOpen(true);
          break;
        case ACCESS_CODES_API_RES_TYPES.UNAVAILABLE:
          const doesAccessCodeStartInTheFuture =
            new Date(startTime) > new Date();
          if (doesAccessCodeStartInTheFuture) {
            setIsAccessCodeModalOpen(true);
          }
          break;
        default:
          if (
            altAccessCode &&
            altAccessCodeConfig &&
            isAccessCodeActiveByDate(startTime, endTime) &&
            isAccessCodeActiveByDate(
              altAccessCodeConfig.startTime,
              altAccessCodeConfig.endTime
            )
          ) {
            if (isStreamlinedFlow) {
              navigator.gotoUsageSelectionPage();
            } else {
              navigator.gotoEligibilityIntroPage();
            }
          } else if (isAccessCodeActiveByDate(startTime, endTime)) {
            if (isVirginPulse || isPersonifyHealth || isStreamlinedFlow) {
              if (
                !showAuthenticationModal &&
                phAuthState === PH_AUTH_STATES.SESSION_EXPIRED
              ) {
                setShowAuthenticationModal(true);
              } else {
                navigator.gotoUsageSelectionPage();
              }
            } else {
              navigator.gotoEligibilityIntroPage();
            }
          }
          break;
      }
    }
    if (accessCodeCheckRes?.status === ACCESS_CODES_API_RES_TYPES.FAIL) {
      dispatchAccessCodeFetchFail();
    }
  };

  return (
    <>
      {isStreamlinedFlow && (
        <>
          <header className={classnames('brand-bar', styles.brandBar)}>
            <div className={classnames('wrapper', styles.mainHeader)}>
              <HeaderLogo cssStyleName={styles.headerLogo} />
            </div>
          </header>
          <StepsProgressBar
            currentStepToHighlight={1}
            isPreviousStepComplete={false}
          />
          <Wrapper pageName={styles.streamlinedContainer}>
            <h1 className="step-header streamlined">
              {str(I18N_STR.lpHeader)}
            </h1>
            <div className={styles.streamlinedImg}>
              <img
                src={AboutBreatheImg}
                className={styles.streamlinedImg}
                alt=""
                loading="lazy"
              />
            </div>
            <FormFooter>
              <button
                className="next-frame-button streamlined"
                onClick={getStarted}
                disabled={false}
              >
                Next
              </button>
            </FormFooter>
            <div className="step-copy streamlined">
              {str(I18N_STR.lpCopy1)} {str(I18N_STR.lpDisclaimer)}
            </div>
            {accessCodeConfig?.webTemplate?.descriptionCopy && (
              <span
                className={styles.eligibilityNote}
                dangerouslySetInnerHTML={{
                  __html: accessCodeConfig.webTemplate.descriptionCopy,
                }}
              />
            )}
            {isTarget && (
              <div className={styles.flyerWrapper}>
                <TargetSpanishFlyer />
              </div>
            )}
          </Wrapper>
          {isTarget && <TargetReasonableAlternative />}
          {isBcnc && <BcncLegalFooter />}
          {isJib && <JibLegalFooter />}
        </>
      )}

      {!isStreamlinedFlow && (
        <>
          <FixedHeader callToAction={getStarted} />
          <Hero
            callToAction={getStarted}
            accessCode={accessCode}
            partner={params?.partner}
            accessCodeFromUrl={accessCodeFromUrl}
            nicType={nicType}
          />

          {isTarget && <TargetReasonableAlternative />}
          {nicType === ADDICTION_TYPES.SMOKING && (
            <>
              <SectionAppSensor callToAction={getStarted} />
              <SectionQuitKit callToAction={getStarted} />
              <SectionCoreLoop callToAction={getStarted} />
              <SectionNrt callToAction={getStarted} />
              <SectionCoaching callToAction={getStarted} />
              <TestimonialsSlider set={nicType} />
              <InteractiveCTA
                cta={getStarted}
                inModal={false}
                cssClassName={styles.interactiveCTA}
              />
              <ExitIntentModalInteractiveCTA
                cta={getStarted}
                cssClassName={styles.interactiveCTA}
              />
            </>
          )}
          {nicType === ADDICTION_TYPES.VAPING && (
            <>
              <SectionVapeCravings callToAction={getStarted} />
              <SectionVapeCoaching callToAction={getStarted} />
              <SectionVapePace callToAction={getStarted} />
              <SectionVapeQuitKit callToAction={getStarted} />
              <SectionVapeFun callToAction={getStarted} />
            </>
          )}
          {nicType === ADDICTION_TYPES.OTHER && (
            <>
              <SectionOtherDiscover callToAction={getStarted} />
              <SectionOtherCoaching callToAction={getStarted} />
              <SectionOtherCommunity callToAction={getStarted} />
              <TestimonialsSlider set={nicType} />
            </>
          )}
          {isBcnc && <BcncLegalFooter />}
          {isJib && <JibLegalFooter />}
          <PrivacyPolicy />
          <VirginPulseAuthenticateModal authState={vpAuthState} />
          <VirginPulseThirdPartyCookiesModal authState={vpAuthState} />
          <VirginPulseEmailExistsModal authState={vpAuthState} />
          {showAuthenticationModal ? (
            <PersonifyHealthAuthenticateModal authState={phAuthState} />
          ) : null}
          <PersonifyHealthThirdPartyCookiesModal authState={phAuthState} />
          <PersonifyHealthEmailExistsModal authState={phAuthState} />
        </>
      )}
      <AccessCodeNotYetOpenModal
        closeModal={() => setIsAccessCodeModalOpen(false)}
        isActive={isAccessCodeModalOpen}
      />
      <MessageModal
        messageType={messageParam}
        closeModal={() => setIsMessageModalOpen(false)}
        isActive={isMessageModalOpen}
      />
    </>
  );
}
