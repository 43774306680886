import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PAGES } from '../../../page-urls';
import { userManagementRootEndpoint } from '../../../../config';
import { useEffect, useState } from 'react';
import {
  ACTION_TYPES,
  setUserRegistrationField,
} from '../../../../state/actions';

export const LOCAL_STORAGE_PH_AUTH_TIMESTAMP_KEY = 'pivot_ph_auth_timestamp';

export const PH_AUTH_STATES = {
  THIRD_PARTY_COOKIE_NOT_SUPPORTED: 'THIRD_PARTY_COOKIE_NOT_SUPPORTED',
  IDLE_TIMEOUT: 'IDLE_TIMEOUT', // when user leaves browser open and session expires
  SESSION_EXPIRED: 'SESSION_EXPIRED', // when user "comes back" and cookie is expired
  EMAIL_ALREADY_REGISTERED: 'EMAIL_ALREADY_REGISTERED',
};

export function usePersonifyHealthAuth() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isPersonifyHealth = location.pathname.includes(
    `${PAGES.PERSONIFY_HEALTH_LANDING.url}/`
  );
  const urlParams = useSelector(state => state.urlParams);
  const [authState, setAuthState] = useState();

  const tryGettingThirdPartyCookie = err => {
    fetch(`${userManagementRootEndpoint}/v1/login/saml/check-cookie`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => {
        if (res?.status !== 'success') {
          throw Error;
        }
        if (!res.data || res.data?.thirdPartyCookiesSupported === false) {
          setAuthState(PH_AUTH_STATES.THIRD_PARTY_COOKIE_NOT_SUPPORTED);
          return;
        } else {
          setAuthState(PH_AUTH_STATES.SESSION_EXPIRED);
          return;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isPersonifyHealth) {
      dispatch({ type: ACTION_TYPES.SET_IS_PERSONIFY_HEALTH });

      fetch(
        `${userManagementRootEndpoint}/v1/login/saml/profile?accessCode=${urlParams.access_code}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then(res => res.json())
        .then(res => {
          if (res?.status === 'success') {
            // user is logged in and their cookie is valid
            const {
              ssoEmail = '',
              firstName = '',
              lastName = '',
              registeredEmail = false,
            } = res?.data;
            dispatch(setUserRegistrationField('email', ssoEmail));
            dispatch(setUserRegistrationField('firstName', firstName));
            dispatch(setUserRegistrationField('lastName', lastName));
            localStorage.setItem(
              LOCAL_STORAGE_PH_AUTH_TIMESTAMP_KEY,
              Date.now()
            );

            if (registeredEmail) {
              setAuthState(PH_AUTH_STATES.EMAIL_ALREADY_REGISTERED);
            }
          } else {
            throw Error; // user is either not logged in or doesn't have 3rd party cookies enabled - lets verify which
          }
        })
        .catch(err => {
          tryGettingThirdPartyCookie(err);
        });
    }
  }, [urlParams]);

  return { isPersonifyHealth, phAuthState: authState };
}
