import { useEffect, useRef } from 'react';
import PortalModal from '../../../components/PortalModal';
import { PH_AUTH_STATES } from '../personify-health/usePersonifyHealth';
import { EmailSupportLink } from '../common-components/EmailSupportLink';
import { ReactComponent as Image } from '../../../../media/email-exists.svg';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function PersonifyHealthEmailExistsModal({ authState }) {
  const modalRef = useRef();
  const { str } = useI18n();

  useEffect(() => {
    if (
      authState === PH_AUTH_STATES.EMAIL_ALREADY_REGISTERED &&
      modalRef?.current
    ) {
      modalRef.current.focus();
    }
  }, [authState]);

  return authState === PH_AUTH_STATES.EMAIL_ALREADY_REGISTERED ? (
    <PortalModal onTop={true}>
      <div className="obstacle-modal ph-modal email-modal">
        <div className="inner-modal wrapper" ref={modalRef}>
          <Image />
          <h3>{str(I18N_STR.phExistsModalTitle)}</h3>
          <p>{str(I18N_STR.phExistsModalCopy)} </p>
          <div className="form-footer">
            <EmailSupportLink className="cs-button" />
          </div>
        </div>
      </div>
    </PortalModal>
  ) : null;
}
