import { useEffect, useRef } from 'react';
import PortalModal from '../../../components/PortalModal';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import {
  userManagementRootEndpoint,
  personifyHealth,
} from '../../../../config';
import { PH_AUTH_STATES } from '../personify-health/usePersonifyHealth';
import { ReactComponent as PHLogo } from '../../../../media/ph-logo.svg';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function PersonifyHealthAuthenticateModal({ authState }) {
  const modalRef = useRef();
  const { str } = useI18n();

  useEffect(() => {
    if (authState === PH_AUTH_STATES.SESSION_EXPIRED && modalRef?.current) {
      modalRef.current.focus();
    }
  }, [authState]);

  return authState === PH_AUTH_STATES.SESSION_EXPIRED ? (
    <PortalModal onTop={true}>
      <div className="obstacle-modal ph-modal">
        <div className="inner-modal wrapper" ref={modalRef}>
          <PHLogo />
          <h3>{str(I18N_STR.phAuthModalTitle)}</h3>
          <p>{str(I18N_STR.phAuthModalCopy)}</p>
          <div className="form-footer">
            <button
              className="cs-button"
              onClick={() => {
                track(`${WEBREG_PREFIX}:ph-modal-login-attempt`);
                window.location.replace(
                  `${userManagementRootEndpoint}/v1/login/saml?provider=${personifyHealth.authProvider}`
                );
              }}
            >
              {str(I18N_STR.phAuthModalButton)}
            </button>
          </div>
        </div>
      </div>
    </PortalModal>
  ) : null;
}
