import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { PAGES } from '../page-urls';
import PivotAndVPLogo from '../../media/pivot-and-vp-logos.svg';
import PivotAndPHLogo from '../../media/pivot-and-ph-logos.svg';
import Logo from '../../media/pivot-logo-dark.svg';
import { useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';

function getPartnerUrlPrefix(isVirginPulse, isPersonifyHealth) {
  if (isVirginPulse) {
    return PAGES.VIRGIN_PULSE_LANDING.url;
  }
  if (isPersonifyHealth) {
    return PAGES.PERSONIFY_HEALTH_LANDING.url;
  }
  return PAGES.PARTNER_LANDING.url;
}

function getPartnerLogo(accessCodeConfig, isVirginPulse, isPersonifyHealth) {
  if (isVirginPulse) {
    return PivotAndVPLogo;
  }
  if (isPersonifyHealth && !accessCodeConfig?.webTemplate?.logoUrl) {
    return PivotAndPHLogo;
  }
  return accessCodeConfig?.webTemplate?.logoUrl || null;
}

export function HeaderLogo({ cssStyleName = null }) {
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const accessCode = accessCodeConfig?.accessCode;
  const alias =
    accessCodeConfig?.alias ||
    accessCodeConfig?.webTemplate?.alias?.toLowerCase();
  const isVirginPulse = useSelector(state => state.isVirginPulse);
  const isPersonifyHealth = useSelector(state => state.isPersonifyHealth);
  const partnerUrlPrefix = getPartnerUrlPrefix(
    isVirginPulse,
    isPersonifyHealth
  );
  const accessCodeParam = accessCode ? `?access_code=${accessCode}` : '';
  const homeLink = alias
    ? `${partnerUrlPrefix}/${alias}${accessCodeParam}`
    : '';
  const partnerLogo = getPartnerLogo(
    accessCodeConfig,
    isVirginPulse,
    isPersonifyHealth
  );
  const { str } = useI18n();

  return (
    <a
      href={`${window.location.origin}${homeLink}`}
      className={classnames('header-logo', cssStyleName)}
    >
      <img
        src={partnerLogo || Logo}
        alt={str(I18N_STR.logoAlt)}
        width="155"
        height="34"
      />
    </a>
  );
}
