import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { accessCodes } from '../../../config';
import {
  ADDICTION_TYPES,
  AGE_CATEGORY,
  PIVOTCORE_ACCESS_CODE,
  PARTNERS,
} from '../../constants';
import { PAGES } from '../../page-urls';
import { isRealtimeEligibilityCheck } from './eligibility/utils';
import { getPrincipalAddictionType } from './registration-utils';

const WAITLIST_SURVEY_URL_GENERIC = 'https://www.surveymonkey.com/r/LZZ2ZWR';
const WAITLIST_SURVEY_URL_FL_BLUE = 'https://www.surveymonkey.com/r/FBwaitlist';

export function useRegistrationNavigator() {
  const navigate = useNavigate();
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);

  const isEligibilityWithClaims = () => {
    return isRealtimeEligibilityCheck(accessCodeConfig?.eligibility);
  };

  const isFreemiumCode = () => {
    return accessCodeConfig.accessCode === PIVOTCORE_ACCESS_CODE;
  };

  const isAccessCodeForMinors = () => {
    return accessCodeConfig?.ageCategory === AGE_CATEGORY.MINOR;
  };

  const navigator = {
    navigateToURL: (url = PAGES.START.url) => {
      navigate(url);
    },
    goToStartPage: () => {
      navigate(PAGES.START.url);
    },

    gotoHomeOrPartnerPage: () => {
      let urlFragment = PAGES.START.url;
      if (accessCodeConfig && accessCodeConfig.webTemplate !== null) {
        const alias = accessCodeConfig?.alias;
        urlFragment = alias
          ? `${PAGES.PARTNER_LANDING.url}/${alias}`
          : PAGES.PARTNER_LANDING.url;
      }
      window.location.replace(window.location.origin + urlFragment);
    },

    gotoUsageSelectionPage: () => {
      navigate(PAGES.NICOTINE_USAGE_SELECTION.url);
    },

    gotoEligibilityIntroPage: () => {
      navigate(PAGES.ELIGIBILITY_INTRO.url);
    },

    gotoPrimaryEligibilityPage: () => {
      navigate(PAGES.ELIGIBILITY_PRIMARY.url);
    },

    gotoSecondaryEligibilityPage: () => {
      navigate(PAGES.ELIGIBILITY_SECONDARY.url);
    },

    gotoEligibilitySubmitToReviewPage: () => {
      navigate(PAGES.REVIEW_ELIGIBILITY.url);
    },

    gotoEligibilityApprovedPage: () => {
      navigate(PAGES.ELIGIBILITY_APPROVED.url);
    },

    gotoCreateAccountPage: accessCode => {
      if (accessCode === accessCodes.fox21) {
        navigate(PAGES.CREATE_ACCOUNT_FOX.url);
      } else {
        navigate(PAGES.CREATE_ACCOUNT.url);
      }
    },

    goToInitialCreateAccountSection: () => {
      const addictionType = getPrincipalAddictionType();
      const nextPage =
        (addictionType === ADDICTION_TYPES.SMOKING &&
          !isAccessCodeForMinors()) ||
        isEligibilityWithClaims()
          ? PAGES.SHIPPING.url
          : PAGES.CREDENTIALS.url;
      navigate(`${PAGES.CREATE_ACCOUNT.url}/${nextPage}`);
    },

    gotoNextCreateAccountSection: comingFrom => {
      const addictionType = getPrincipalAddictionType();
      const isFreemium = isFreemiumCode();
      let nextPage = '';
      switch (comingFrom) {
        case PAGES.PERSONAL.url:
          nextPage =
            addictionType === ADDICTION_TYPES.SMOKING &&
            !isAccessCodeForMinors() &&
            !isFreemium
              ? PAGES.SHIPPING.url
              : PAGES.CREDENTIALS.url;
          break;
        case PAGES.SHIPPING.url:
          nextPage = PAGES.CREDENTIALS.url;
          break;
        default:
          break;
      }
      navigate(`${PAGES.CREATE_ACCOUNT.url}/${nextPage}`);
    },

    gotoAfterCreateAccount: () => {
      navigate(PAGES.DOWNLOAD_APP.url);
    },

    gotoShortlistPage: accessCode => {
      const isFlBlue = PARTNERS.FLBLUE.accessCodes.includes(accessCode);
      const waitlistSurveyUrl = isFlBlue
        ? WAITLIST_SURVEY_URL_FL_BLUE
        : WAITLIST_SURVEY_URL_GENERIC;
      window.location.href = `${waitlistSurveyUrl}?code=${accessCode}`;
    },
  };

  return { navigator };
}
